import React from 'react';
import './Header.css'; // Ensure this CSS file handles responsive layouts well
import { FiPhoneCall, FiMail } from 'react-icons/fi'; 
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import logo from './logo.webp';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <>
            <div className="header-container">
                <div className="header-item"> 
                    <a href="tel:+919205330861" aria-label="Phone"><FiPhoneCall /> +919205330861</a>
                </div>
                <div className="header-item">
                    <a href="mailto:himanshudeshwal@metaadfox.com" aria-label="Email">
                        <FiMail /> himanshudeshwal@metaadfox.com
                    </a>
                </div>
                <div className="header-item header-hitman">
                    <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <FaInstagram className="instagram-icon"/> 
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=919205330861" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                        <FaWhatsapp className="whatsapp-icon" /> 
                    </a>
                </div>
            </div>
            <div className="main-header">
                <div className="company-branding">
                    <img src={logo} alt="Company Logo" className="company-logo" />
                    <span className="company-tagline">Where Ads Influence & Inspire</span>
                </div>
                <div className="header-navigation">
                    <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>HOME</NavLink>
                    <NavLink to="/services" className={({ isActive }) => isActive ? 'active' : ''}>SERVICES</NavLink>
                    <NavLink to="/portfolio" className={({ isActive }) => isActive ? 'active' : ''}>PORTFOLIO</NavLink>
                    <NavLink to="/influencers" className={({ isActive }) => isActive ? 'active' : ''}>INFLUENCERS</NavLink>
                    <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>ABOUT</NavLink>
                    <NavLink to="/contact" className={({ isActive }) => isActive ? 'active' : ''}>CONTACT US</NavLink>
                </div>
            </div>
        </>
    );
};

export default Header;
