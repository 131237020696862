import React, { useState } from 'react';
import './Home.css'; // Ensure this file is properly linked
import { FaTags, FaUserFriends } from 'react-icons/fa';

const Home = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    // Function to open the modal with specific content
    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    return (
        <>
        <div className="home-background">
            <div className="home-text">
                <h1>
                    <span className="innovate">INNOVATE</span>
                    <span className="word-gap"></span>
                    <span className="influence">INFLUENCE</span>
                    <span className="word-gap"></span>
                    <span className="inspire">INSPIRE</span>
                </h1>
                <div className="Home-Buttons">
                    <button type="button" onClick={() => openModal('brand')}><FaTags /> Join as a Brand</button>
                    <button type="button" onClick={() => openModal('influencer')}><FaUserFriends /> Sign Up as an Influencer</button>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                        {modalContent === 'brand' ? (
                            <div>
                                <h2>Join as a Brand</h2>
                                <form>
                                    <label htmlFor="brandName">Brand Name:</label>
                                    <input type="text" id="brandName" name="brandName" required />
                                    <label htmlFor="websiteLink">Website/App Link:</label>
                                    <input type="url" id="websiteLink" name="websiteLink" required />
                                    <label htmlFor="representativeName">Representative Name:</label>
                                    <input type="text" id="representativeName" name="representativeName" required />
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" id="email" name="email" required />
                                    <label htmlFor="phone">Phone Number:</label>
                                    <input type="tel" id="phone" name="phone" required />
                                    <label htmlFor="service">Influencer Marketing Service:</label>
                                    <select id="service" name="service" required>
                                        <option value="Youtube">Youtube</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="GoogleAds">Google Ads</option>
                                        <option value="FacebookAds">Facebook Ads</option>
                                    </select>
                                    <button type="submit">Submit</button>
                                </form>
                              
                            </div>
                        ) : (
                            <div>
                                <h2>Sign Up as an Influencer</h2>
                                <form>
                                    <label htmlFor="influencerBrandName">Name</label>
                                    <input type="text" id="influencerBrandName" name="influencerBrandName" required />
                                    <label htmlFor="socialMediaLinks">Social Media Handles Link:</label>
                                    <input type="url" id="socialMediaLinks" name="socialMediaLinks" required multiple placeholder="e.g., https://instagram.com/yourhandle" />
                                    <label htmlFor="influencerRepresentativeName">Representative Name:</label>
                                    <input type="text" id="influencerRepresentativeName" name="influencerRepresentativeName" required />
                                    <label htmlFor="influencerEmail">Email:</label>
                                    <input type="email" id="influencerEmail" name="influencerEmail" required placeholder='e.g., example@gmail.com'/>
                                    <label htmlFor="influencerPhone">Phone Number:</label>
                                    <input type="tel" id="influencerPhone" name="influencerPhone" required />
                                    <label htmlFor="influencerService">Influencer Marketing Service:</label>
                                    <select id="influencerService" name="influencerService" required>
                                        <option value="Youtube">Youtube</option>
                                        <option value="Instagram">Instagram</option>
                                    </select>
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
        <div className="frame-2">
        <h2>Top Influencers</h2>
        <div className="top-influencers">
          {/* Manually adding influencer cards */}
          <div className="influencer-card">
            <img src="url-to-image" alt="Influencer 1" className="influencer-image"/>
            <div className="influencer-info">
              <h3>Influencer 1</h3>
              <p>Description of Influencer 1</p>
            </div>
          </div>

          {/* Add more influencer cards as needed */}
        </div>
      
      
    </div>
        </>
    );
};

export default Home;
